import React from 'react';
import theme from './theme';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
// import { SignupCostumerContextProvider } from './context';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryConfig = { defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } } };
const queryClient = new QueryClient(queryConfig);

// const CurrentStep = React.lazy(() => import('./components/CurrentStep'));

import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import { useMountEffect } from '@react-hookz/web';
import { useSegment } from '@/hooks/useSegment';
import { useNetworkManager } from '@/hooks/useNetworkManager';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { ErrorBoundary } from '@sentry/react';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { Phone } from '@mui/icons-material';

const App = () => {
  const { isConnectionSlow } = useNetworkManager();
  const { trackLanding, trackEvent } = useSegment();

  const onCallClick = () => {
    trackEvent('Call Toll Free', {
      screen: 'Under construction'
    });
  };

  useMountEffect(() => {
    trackLanding(isConnectionSlow ? 'lite' : 'default');
    // remove spinner in index.html
    const initialLoader = document.getElementById('initial-loading');
    if (initialLoader) {
      initialLoader.remove();
    }
  });

  const forceRefresh = async () => {
    if (window?.caches) {
      const { caches } = window;
      const cacheNames = await caches.keys();
      const cacheDeletionPromises = cacheNames.map(n => caches.delete(n));

      await Promise.all(cacheDeletionPromises);
    }
    window.location.reload(true);
  };

  const failedToFetchRegex = new RegExp(/failed to fetch/i);

  return (
    <QueryClientProvider client={queryClient}>
      <MuiThemeProvider theme={theme}>
        <EmotionThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <BrowserRouter basename="/commercial">
              <ErrorBoundary
                onError={error => {
                  if (failedToFetchRegex.test(error.message)) {
                    forceRefresh();
                  }
                }}
              >
                <Route
                  exact
                  path="/"
                  render={() => (
                    <Grid
                      container
                      direction="row"
                      height="100vh"
                      width="100vw"
                      sx={{
                        background: '#1B3838'
                      }}
                    >
                      <Grid item xs={0} lg={8} m="0 auto" sx={{ opacity: 0.75, filter: 'grayscale(50%)' }}>
                        <img
                          width="100%"
                          height="100%"
                          loading="eager"
                          src="/assets/lib/core/images/under-construction.jpg"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={4}
                        m="auto"
                        p={16}
                        alignItems="center"
                        justifyContent="center"
                        textAlign="center"
                        height={{ xs: '100%', md: 'auto' }}
                        width="100%"
                      >
                        <Stack justifyContent="center" alignItems="center">
                          <Stack>
                            <Typography variant="bold" color="#B6CD01" fontSize={24}>
                              This site is currently under construction.
                            </Typography>
                            <br />
                            <Typography variant="regular" color="#B6CD01" fontSize={18}>
                              Please call Apollo Toll-Free Line at <b style={{ textWrap: 'nowrap' }}>0800 722 950</b> to
                              sign up with Apollo this season!
                            </Typography>
                            <br />
                          </Stack>

                          <Button onClick={onCallClick} size="large" startIcon={<Phone />} href="tel:+254800722950">
                            Call Apollo now!
                          </Button>

                          <Stack mt={8} gap={2}>
                            <Typography variant="regular" color="primary">
                              <Typography color="primary.dark" mr={4} variant="bold">
                                Weekdays
                              </Typography>{' '}
                              <Typography fontWeight={100} variant="regular" fontSize={14}>
                                7am - 7pm
                              </Typography>
                            </Typography>
                            <Typography variant="regular" color="primary">
                              <Typography color="primary.dark" mr={4} variant="bold">
                                Saturday
                              </Typography>{' '}
                              <Typography fontWeight={100} variant="regular" fontSize={14}>
                                8am - 5pm
                              </Typography>
                            </Typography>
                          </Stack>
                        </Stack>
                      </Grid>
                    </Grid>
                  )}
                />
                <Route path="*" render={() => <Redirect to={{ pathname: '/', search: location.search }} />} />
              </ErrorBoundary>
            </BrowserRouter>
          </LocalizationProvider>
        </EmotionThemeProvider>
      </MuiThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
